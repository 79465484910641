import PoppinsRegularWOFF from "../assets/fonts/Poppins-Regular.woff";
import PoppinsMediumWOFF from "../assets/fonts/Poppins-Medium.woff";
import PoppinsLightWOFF from "../assets/fonts/Poppins-Light.woff";
import PoppinsBoldWOFF from "../assets/fonts/Poppins-Bold.woff";
import EnterTheGridWOFF from "../assets/fonts/Enter-The-Grid.woff";
import FuroreWOFF from "../assets/fonts/Furore.woff";

const furore = {
  fontFamily: "Furore",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('Furore'),
		local('Furore'),
		url(${FuroreWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const poppinsRegular = {
  fontFamily: "Poppins-Regular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('PoppinsRegular'),
		local('Poppins-Regular'),
		url(${PoppinsRegularWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const poppinsMedium = {
  fontFamily: "Poppins-Medium",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('PoppinsMedium'),
		local('Poppins-Medium'),
		url(${PoppinsMediumWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const poppinsLight = {
  fontFamily: "Poppins-Light",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('PoppinsLight'),
		local('Poppins-Light'),
		url(${PoppinsLightWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const poppinsBold = {
  fontFamily: "Poppins-Bold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('PoppinsBold'),
		local('Poppins-Bold'),
		url(${PoppinsBoldWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};
const enterTheGrid = {
  fontFamily: "Enter-The-Grid",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
		local('EnterTheGrid'),
		local('Enter-The-Grid'),
		url(${EnterTheGridWOFF}) format('woff')
	`,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const fonts = [poppinsRegular,poppinsLight,poppinsBold,poppinsMedium,enterTheGrid,furore];

export default fonts;
