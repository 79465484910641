import { Box, Hidden } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch } from "react-redux";

import './header.scss';
import './tveffect.css'
import Logo from '../../assets/images/logo.png'
import { info } from "src/slices/MessagesSlice";

export default function Header() {

    const isSmallScreen = useMediaQuery("(max-width: 1080px)");

    const dispatch = useDispatch()
    const handleOpen = () => {
        dispatch(info("coming soon"))
    }

    return (
        <Box display='flex' justifyContent='center' className='header-view'>
            <Box display='flex' justifyContent={isSmallScreen ? "center" : "space-between"} alignItems='center' className="container header font-24">
                {
                    !isSmallScreen &&
                    <Box display='flex' className="menuList">
                        <a href="#overview" className="menuItem">
                            <div className="color1">Overview</div>
                        </a>
                        <a href="#roadmap" className="menuItem">
                            <div className="color1">Roadmap</div>
                        </a>
                        <a href="https://apeparkdao.gitbook.io/docs/" target='_blank' className="menuItem">
                            <div className="color1">Docs</div>
                        </a>
                    </Box>
                }
                <img src={Logo} style={{ height: 24 }} />
                {
                    !isSmallScreen &&
                    <a href="https://app.apepark.money/dashboard" target='_blank'>                   
                    <Box display='flex' justifyContent='center' alignItems='center'
                        className="enterBtn b10_tveffect color2 font-Furore">
                        <div>ENTER DAPP</div>
                        <div>
                            <div>ENTER DAPP</div>
                            <div>ENTER DAPP</div>
                            <div>ENTER DAPP</div>
                        </div>
                    </Box>
                    </a>
                }
            </Box>
            {
                isSmallScreen &&
                <Box display='flex' justifyContent='space-around' alignItems='center' className="menuList-m font-16">
                    <a href="#overview">
                        <div className="color1">Overview</div>
                    </a>
                    <a href="#roadmap">
                        <div className="color1">Roadmap</div>
                    </a>
                    <a href="https://apeparkdao.gitbook.io/docs/" target='_blank'>
                        <div className="color1">Docs</div>
                    </a>
                </Box>
            }
        </Box>
    )
}